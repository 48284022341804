<template>
  <TopNavBar />

  <!-- breadcrumb area start -->
  <div
    class="breadcrumb-area"
    style="background-image: url(assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">Contact</h1>
            <ul class="page-list">
              <li>
                <router-link class="nav-link" :to="{ name: 'home' }"
                  >Home</router-link
                >
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb area End -->

  <LetsTalk />
  <Footer />

  <!-- back to top area start -->
  <div class="back-to-top">
    <span class="back-top"><i class="fa fa-angle-up"></i></span>
  </div>
  <!-- back to top area end -->
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
};
</script>

<style scoped>
.mb-8 {
  margin-bottom: 8rem !important;
}
</style>
